import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Header } from "../components";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import HashLoader from "react-spinners/HashLoader";
import { showErrorToast } from "../Utils/toastUtils";

import {
	GridComponent,
	ColumnsDirective,
	ColumnDirective,
	Page,
	Selection,
	Inject,
	Edit,
	Toolbar,
} from "@syncfusion/ej2-react-grids";

import ContractForm from "./Forms/publisherForm";

const PublisherFormDetails = () => {
	const [publisherData, setPublisherData] = useState(null);
	const [publisherId, setPublisherId] = useState(null);
	const [contractID, setContractID] = useState(null);
	const [contracts, setContracts] = useState([]);
	const navigate = useNavigate();
	const userType = sessionStorage.getItem("role");
	const isEdit = sessionStorage.getItem("isEdit");
	const { currentColor } = useStateContext();
	const [loading, setLoading] = React.useState(false);
	const userDetails = sessionStorage.getItem("UserDetails");

	const parsedData = JSON.parse(userDetails);


	const selectionsettings = { persistSelection: true };
	// const toolbarOptions = ["Delete"];
	const editing = { allowDeleting: true, allowEditing: true };

	const handleButtonClick = () => {
		navigate(`/publisher/docs/${publisherId}`);
	};

	const handleContractClick = useCallback((args) => {
		// console.log(args.data._id);
		// console.log("CLikced");
		const targetElement = args.target;
		if (targetElement.classList.contains("btn-edit")) {
			handleClick(args);
		} else {
			// console.log(args);
			if(userType === "SubPublisher" && parsedData.isEdit === false){
				// showErrorToast("You are not allowed to edit this contract");
				console.log("You are not allowed to edit this contract");
				return;
			}
			let clickedContractId = args.data._id;
			// setContractID(clickedContractId);
			navigate(`/form/${clickedContractId}`);
		}
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				if(userType === "Publisher"){
					const result = await axios.get(
						`/api/publisher/getpubbId`,
						{
							headers: {
								Authorization: "Bearer " + sessionStorage.getItem("Token"),
							},
						}
					);
					setPublisherId(result.data._id);
					setPublisherData(result.data);

				}else if(userType === "SubPublisher"){
					const result = await axios.get(
						`/api/subPublisher/getSubPubId`,
						{
							headers: {
								Authorization: "Bearer " + sessionStorage.getItem("Token"),
							},
						}
					);
					setPublisherId(result.data.publisherId._id);
					setPublisherData(result.data.publisherId);
				}


			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const fetchContracts = async () => {
			if (publisherId !== null) {
				try {
					setLoading(true);
					const result = await axios.get(
						`/api/contract/get/all/${publisherId}`,
						{
							headers: {
								Authorization: "Bearer " + sessionStorage.getItem("Token"),
							},
						}
					);
					setContracts(result.data);
				} catch (error) {
					console.log(error);
				} finally {
					setLoading(false);
				}
			}
		};
		fetchContracts();
	}, [publisherId]);

	const handleClick = async (args) => {
		if( args.data.approvalStatus || args.data.approvalStatus === "Approved"){
			// navigate(`https://crmdocs.adsolut.in/Contract-${args.data._id}.pdf`);
			window.open(`https://crmdocs.adsolut.in/Contract-${args.data._id}.pdf`, "_blank")
		} else {
			try {
				setLoading(true);
				await axios
					.post(`/api/pdf/generatePdfView/${args.data._id}`, {
						headers: {
							Authorization: "Bearer " + sessionStorage.getItem("Token"),
						},
					})
					.then((response) => {
						window.open(`https://crmdocs.adsolut.in/Contract-${args.data._id}-View.pdf`, "_blank")
						// navigate(`https://crmdocs.adsolut.in/Contract-${args.data._id}-View.pdf`);
					})
					.catch((error) => {
						console.log(error);
					});
			} catch (error) {
				showErrorToast(error);
			} finally {
				setLoading(false);
			}
			
		}
	};

	const handleDownload = (args) => {
		// console.log(args.data?._id);
		return (
			<button
				className={`underline text-blue-500 hover:text-blue-800 btn-edit`}
			>
				View Document
				{/* <FiDownload /> */}
			</button>
		);
	};

	if (userType === "Publisher" || userType === "SubPublisher") {
		return (
			<div className="m-2 md:my-10 mt-24 p-2 md:p-10 bg-white mid:max-w-xl semi-mid:mx-10 semi-mid:min-w-fit rounded-3xl mx-auto">
				{publisherData ? (
					<>
						<Header category="Page" title={publisherData.name} />
						<div className="flex justify-between items-center ">
							<div>
								<h2>ID: {publisherData._id}</h2>
								{/* <h2>Employee ID: {publisherData.employeeId._id}</h2> */}
								<h2>Name: {publisherData.name}</h2>
							</div>

							<button
								style={{ background: currentColor }}
								onClick={handleButtonClick}
								className=" text-white font-bold py-2 px-4 rounded"
							>
								Get Documents
							</button>
						</div>
					</>
				) : (
					<>
						<Header category="Page" title="Publisher Info" />
						{loading && (
							<div class="flex justify-center m-3">
								<HashLoader color={currentColor} />
							</div>
						)}
					</>
				)}

				<div className="flex flex-col justify-center items-center">
					<div className="mt-10 text-lg font-medium flex flex-col w-full  ">
						<div>
							{loading ? (
								<div class="flex justify-center m-3">
									<HashLoader color={currentColor} />
								</div>
							) : (
								<GridComponent
									// width="100%"
									dataSource={contracts}
									enableHover={true}
									allowPaging
									pageSettings={{ pageCount: 5 }}
									selectionSettings={selectionsettings}
									// toolbar={toolbarOptions}
									// editSettings={editing}
									allowSorting
									rowSelected={(e) => handleContractClick(e)}
									columns={[
										{ field: "_id", headerText: "ID", isPrimaryKey: true },
										{ field: "publisherId.name", headerText: "Name" },
										{ field: "startDate", headerText: "Start Date" },
										{ field: "period", headerText: "Period" },
										{ field: "<FiDownload />", headerText: "Download" },
									]}
								>
									{/* // <GridComponent
								// 	// id="userGrid"
								// 	dataSource={contracts}
								// 	width="auto"
								// 	// toolbar={toolbarOptions}
								// 	allowPaging
								// 	allowSorting
								// 	pageSettings={{ pageCount: 5 }}
								// 	rowSelected={(args) => {
								// 		handleContractClick(args.data._id);
								// 	}}
								// > */}
									<ColumnsDirective>
										<ColumnDirective field="_id" headerText="ID" width="150" />
										<ColumnDirective
											field="publisherId.name"
											headerText="Name"
											width="120"
										/>
										<ColumnDirective
											field="startDate"
											headerText="Start Date"
											width="120"
										/>
										<ColumnDirective
											field="period"
											headerText="Period"
											width="120"
										/>
										<ColumnDirective
											template={handleDownload}
											headerText="Download"
											width="120"
										/>
									</ColumnsDirective>

									<Inject services={[Page, Selection, Toolbar, Edit]} />
								</GridComponent>
							)}
						</div>
						<div>
							{contractID && (
								<div>
									<ContractForm />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default PublisherFormDetails;
