import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStateContext } from "../contexts/ContextProvider";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const NewUserModal = (props) => {
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [perm, setPerm] = useState("View")
  const [showPassword, setShowPassword] = useState(false);
  const [emailId, setEmailId] = useState("");

  const getData = props.getData;
  const modal = props.modal;
  const setModal = props.setModal;

  const { currentColor } = useStateContext();

  const toggleModal = () => {
    setModal(!modal);
    setUserName("");
    setUserPassword("");
    getData();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (
      userName.length === 0 ||
      userPassword.length === 0 ||
      perm.length === 0
    ) {
      toast.error("Please fill in all the fields.");
      return;
    }

    // cm
    const formData = {
      name:userName,
      emailId: emailId,
      password: userPassword,
      permissions: perm,
    };

    axios
      .post("/api/subPublisher/createSubPublisher", formData, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("Token"),
        },
      })
      .then((response) => {
        toggleModal();
        getData();
        toast.success(response.data?.Message || "Success");
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        toast.error("Registration Error!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="flex items-center justify-center">
      {modal && (
        <>
          <div className="fixed z-50 inset-0 flex items-center justify-center">
            <div className="w-full max-w-xl mx-auto my-6 bg-white rounded-lg shadow-lg">
              <div className="px-8 py-6">
                <div className="flex justify-between items-center mb-6">
                  <div className="text-xl font-bold">Create New User</div>
                  <button
                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    onClick={toggleModal}
                  >
                    <svg
                      className="h-6 w-6 fill-current"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 8.586l4.95-4.95a1 1 0 0 1 1.415 1.415L11.414 10l4.95 4.95a1 1 0 0 1-1.415 1.415L10 11.414l-4.95 4.95a1 1 0 1 1-1.415-1.415L8.586 10 3.636 5.05A1 1 0 0 1 5.05 3.636L10 8.586z"
                      />
                    </svg>
                  </button>
                </div>
                <form onSubmit={handleSubmit}>
                <div className="mb-6">
                    <label
                      htmlFor="userName"
                      className="block text-gray-700 font-bold mb-2"
                    >
                      User Name
                    </label>
                    <input
                      type="text"
                      id="userName"
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      required
                      placeholder="Enter Email Id"

                    />
                  </div>
                  <div className="mb-6">
                    <label
                      htmlFor="emailId"
                      className="block text-gray-700 font-bold mb-2"
                    >
                      Email-ID
                    </label>

                    <input
                      type="email"
                      id="emailId"
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                      required
                      placeholder="Enter Email Id"

                    />
                  </div>
                  <div className="mb-6">
                    <label
                      htmlFor="userPassword"
                      className="block text-gray-700 font-bold mb-2"
                    >
                      Password
                    </label>
                    <div className="flex justify-end items-center bg-gray-50 border border-gray-300 rounded-lg dark:border-gray-600 relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder="••••••••"
                        className="bg-gray-50 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white"
                        onChange={(e) => setUserPassword(e.target.value)}
                        required
                      />
                      <div className="flex p-1 absolute">
                        <div
                          className="p-2 rounded-full cursor-pointer hover:shadow-lg"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <FaEye className="text-lg" />
                          ) : (
                            <FaEyeSlash className="text-lg" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-6">
                    <label
                      htmlFor="role"
                      className="block text-gray-700 font-bold mb-2"
                    >
                      Role
                    </label>
                    <select
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                      aria-label="Default select example"
                      onChange={(e) => setPerm(e.target.value)}
                      style={{ padding: "12px", color: "#495057" }}
                    >
                     
                            <option value="View">View Only</option>
                            <option value="Edit">Edit Only</option>

                    </select>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      style={{ background: currentColor }}
                      className={`${isLoading
                          ? "bg-gray-500 hover:bg-gray-700"
                          : "bg-blue-500 hover:bg-blue-700"
                        } flex mx-auto  text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out`}
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                      disabled={isLoading}
                    >
                      {isLoading ? "Creating New Sub Publisher..." : "Create New Sub Publisher"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className="fixed inset-0 bg-gray-800 opacity-50"
            onClick={toggleModal}
          ></div>
        </>
      )}
    </div>
  );
};

export default NewUserModal;
