import React, { useEffect } from "react";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { links } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";

const Sidebar = () => {
	const [Url, setUrl] = React.useState("");
	const { currentColor, activeMenu, setActiveMenu, screenSize } =
		useStateContext();

	const handleCloseSideBar = () => {
		if (activeMenu !== undefined && screenSize <= 900) {
			setActiveMenu(false);
		}
	};

	useEffect(() => {
		axios
			.get(`/api/company/get/companyurl`, {
				headers: {
					Authorization: "Bearer " + sessionStorage.getItem("Token"),
				},
			})
			.then((response) => {
				setUrl(response.data.logo);
			});
	}, []);

	const userType = sessionStorage.getItem("role");

	const activeLink =
		"flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
	const normalLink =
		"flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

	return (
		<div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
			{activeMenu && (
				<>
					<div className="flex justify-between items-center ">
						<Link
							to="/dashboard"
							onClick={handleCloseSideBar}
							className="items-center gap-3 ml-3 mt-8 flex  text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
						>
							<img
								src={Url}
								className="object-contain h-auto w-48"
								alt="logo"
							></img>
						</Link>
						<TooltipComponent content="Menu" position="BottomCenter">
							<button
								type="button"
								onClick={() => setActiveMenu(!activeMenu)}
								style={{ color: currentColor }}
								className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
							>
								<MdOutlineCancel />
							</button>
						</TooltipComponent>
					</div>
					<div className="mt-10 ">
						{links.map((item) => (
							<div key={item.title}>
								<p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
									{item.title}
								</p>
								{item.links.map(
									(link) =>
										link.userType.includes(userType) && (
											<NavLink
												to={`/${link.name}`}
												key={link.name}
												onClick={handleCloseSideBar}
												style={({ isActive }) => ({
													backgroundColor: isActive ? currentColor : "",
												})}
												className={({ isActive }) =>
													isActive ? activeLink : normalLink
												}
											>
												{link.icon}
												<span className="capitalize ">{link.name}</span>
											</NavLink>
										)
								)}
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
};

export default Sidebar;
