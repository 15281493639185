import React, { useState, useEffect } from "react";
import axios from "axios";
import { showErrorToast } from "../Utils/toastUtils";
import { useStateContext } from "../contexts/ContextProvider";

const PocPublisher = () => {
	const [userProfile, setUserProfile] = useState({});
	const { currentColor } = useStateContext();
	const [loading, setLoading] = useState(false);
	const fetchData = async () => {
		try {
			setLoading(true);
			const result = await axios.get(
				"/api/dashboard/dash",
				{
					headers: {
						Authorization: "Bearer " + sessionStorage.getItem("Token"),
					},
				}
			);
			const arr = result.data.employee;
			setUserProfile(arr);
		} catch (error) {
			showErrorToast("Error!");

			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchData();
	}, []);
	return (
		<div className="lg:w-6/12 h-100 px-4 mt-9  animate-slide-in-2">
			<div className=" step-two relative bg-green-50 flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
				<div className="rounded-t mb-0 px-4 py-3 border-0 w-100">
					<h3 className="text-lg leading-6 font-medium text-gray-900">
						Employee Profile
					</h3>
					<p className="mt-1 max-w-2xl text-md text-gray-500">
						Hey! This is Point Of Contact information
						<br />
					</p>
				</div>
				<div className="border-t border-gray-200 px-4 py-5 sm:p-0">
					<dl className="sm:divide-y sm:divide-gray-200">
						<div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
							<dt className="text-sm font-medium text-gray-500">Full name</dt>
							<dd className="flex justify-between mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
								{userProfile && userProfile.firstName && userProfile.lastName
									? userProfile.firstName + " " + userProfile.lastName
									: ""}
							</dd>
						</div>
					</dl>
					<dl className="sm:divide-y sm:divide-gray-200">
						<div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
							<dt className="text-sm font-medium text-gray-500">Email</dt>
							<dd className="flex justify-between mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
								{userProfile && userProfile.emailId ? userProfile.emailId : ""}
							</dd>
						</div>
					</dl>
					<dl className="sm:divide-y sm:divide-gray-200">
						<div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
							<dt className="text-sm font-medium text-gray-500">
								Phone Number
							</dt>
							<dd className="flex justify-between mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
								{userProfile && userProfile.ph ? userProfile.ph : ""}
							</dd>
						</div>
					</dl>
				</div>
			</div>
		</div>
	);
};

export default PocPublisher;
