import React, { useState, useEffect } from "react";
import axios from "axios";
import dateFormat from "../../Utils/dateFormatter";
import { useStateContext } from "../../contexts/ContextProvider";
const ContractTableView = (props) => {
	const [formData, setFormData] = useState({});
	const { currentColor } = useStateContext();

	const fetchContract = () => {
		axios
			.get(`/api/sales/one/${props.salesId}`, {
				headers: {
					Authorization: "Bearer " + sessionStorage.getItem("Token"),
				},
			})
			.then((response) => response.data)
			.then((data) => {
				data.startDate = dateFormat(data.startDate);
				data.endDate = dateFormat(data.endDate);
				setFormData(data);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	useEffect(() => {
		fetchContract();
	}, [props.salesId]);

	return (
		<div className="flex items-center justify-center">
			<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl w-3/4 text-white ">
				<h1
					style={{ backgroundColor: currentColor }}
					className="text-xl my-4 bg-gray-200 p-4 rounded-xl"
				>
					General Information
				</h1>
				<div className="relative overflow-x-auto">
					<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
						<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
							<tr className="bg-white dark:bg-gray-800">
								<th className="px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"></th>
								<th className="px-6 py-4 text-lg w-1/3">Advertiser</th>
								<th className="px-6 py-4 text-lg w-1/3">Publisher</th>
							</tr>
						</thead>
						<tbody>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white "
								>
									Company Name
								</th>
								<td className="px-6 py-4">
									{formData
										? formData.companyName
										: "Company Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.salesCompanyName
										: "Company Details Not Found"}
								</td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Street Address
								</th>
								<td className="px-6 py-4">
									{formData
										? formData.streetAddress
										: "Street Address Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.salesStreetAddress
										: "Street Address Details Not Found"}
								</td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									City/State/Zip
								</th>
								<td className="px-6 py-4">
									{formData ? formData.cityStateZip : "State Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.salesCityStateZip
										: "State Details Not Found"}
								</td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Country
								</th>
								<td className="px-6 py-4">
									{formData ? formData.country : "Country Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.salesCountry
										: "Country Details Not Found"}
								</td>
							</tr>

							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Pan Number
								</th>
								<td className="px-6 py-4">
									{formData && formData.panNumber ? formData.panNumber : "-"}
								</td>
								<td className="px-6 py-4">
									{formData && formData.salesPanNumber
										? formData.salesPanNumber
										: "-"}
								</td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									CIN Number
								</th>
								<td className="px-6 py-4">
									{formData && formData.cinNumber ? formData.cinNumber : "-"}
								</td>
								<td className="px-6 py-4">
									{formData && formData.salesCinNumber
										? formData.salesCinNumber
										: "-"}
								</td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Goods & Service Tax No.
								</th>
								<td className="px-6 py-4">
									{formData && formData.gstNumber ? formData.gstNumber : "-"}
								</td>
								<td className="px-6 py-4">
									{formData && formData.salesGstNumber
										? formData.salesGstNumber
										: "-"}
								</td>
							</tr>

							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									VAT
								</th>
								<td className="px-6 py-4">
									{formData && formData.vatNumber ? formData.vatNumber : "-"}
								</td>
								<td className="px-6 py-4">
									{formData && formData.salesTaxID ? formData.salesTaxID : "-"}
								</td>
							</tr>

							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									URL
								</th>
								<td className="px-6 py-4">
									{formData ? formData.url : "URL Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData ? formData.salesUrl : "URL Details Not Found"}
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h1
					style={{ backgroundColor: currentColor }}
					className="text-white text-xl my-4 bg-gray-200 p-4 rounded-xl "
				>
					Special Instructions
				</h1>
				<div className="text-md text-gray-700  my-4 p-4 rounded-xl">
					{formData && formData.comments
						? formData.comments
						: "Comments  Details Not Found"}
				</div>
			</div>
		</div>
	);
};

export default ContractTableView;
