import React, { useState, useEffect, useRef } from "react";
import {
	GridComponent,
	ColumnsDirective,
	ColumnDirective,
	Page,
	Selection,
	Inject,
	Edit,
	Toolbar,
	Sort,
	Search,
} from "@syncfusion/ej2-react-grids";
import { useNavigate } from "react-router-dom";
import { Header } from "../components";
import axios from "axios";
import Modal from "../modals/addPublisher";
import { useStateContext } from "../contexts/ContextProvider";
import HashLoader from "react-spinners/HashLoader";
import handleResponse from "../Utils/responseHandler";
import ChangeEmployeeContract from "../modals/ChangeEmployeeContract";

const Publishers = () => {
	const { currentColor } = useStateContext();
	const toolbarOptions = [
		{ text: "Search", tooltipText: "Search By Name", prefixIcon: "Search" },
	];
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [publishersData, setPublishersData] = useState([]);
	const navigate = useNavigate();
	const userType = sessionStorage.getItem("role");
	const gridRef = useRef(null);
	const [loading, setLoading] = React.useState(false);

	const [modal1, setModal1] = useState(false);
	const [pubId, setPubId] = React.useState("");

	const fetchData = async () => {
		try {
			setLoading(true);
			const result = await axios.get(
				"/api/publisher/get/all",
				{
					headers: {
						Authorization: "Bearer " + sessionStorage.getItem("Token"),
					},
				}
			);
			setPublishersData(result.data);
		} catch (error) {
			handleResponse(error, navigate);
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleRowClick = (args) => {
		const targetElement = args.target;
		if (targetElement.classList.contains("btn-edit")) {
			handleActiveChange(args);
		} else {
			let selectedPublisherId = args.data._id;
			navigate(`/publisher/${selectedPublisherId}`);
		}
	};

	const actionsTemplate = (args) => {
		return (
			<div>
				<label
					className="relative inline-flex items-center cursor-pointer btn-edit"
					onChange={() => {
						handleActiveChange(args);
					}}
				>
					<input
						type="checkbox"
						onChange={console.log()}
						checked={args.uuid.active}
						className="sr-only peer btn-edit"
					/>
					<div
						className={`btn-edit w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 `}
					></div>
				</label>
			</div>
		);
	};

	const handleActiveChange = async (args) => {
		try {
			const result = await axios.put(
				`/api/publisher/active/${args.data.uuid._id}`,
				{ active: !args.data.uuid.active },
				{
					headers: {
						Authorization: "Bearer " + sessionStorage.getItem("Token"),
					},
				}
			);
			fetchData();
		} catch (error) {
			console.log(error);
		}
	};

	const actionsTemplate1 = (args) => {
		console.log(args);
		return (
			<div className="flex flex-row ">
				<button
					style={{ background: currentColor }}
					className="flex  bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out"
					onClick={() => {
						setPubId(args);
						setModal1(true);
					}}
				>
					Change Employee
				</button>
			</div>
		);
	};

	console.log(publishersData);

	if (userType !== "Publisher") {
		return (
			<>
				<div className="m-2 md:my-10 mt-24 p-2 md:p-10 bg-white mid:max-w-xl semi-mid:mx-10 semi-mid:min-w-fit rounded-3xl mx-auto">
					<Header category="Page" title="Publishers" />
					{loading ? (
						<div className="flex justify-center m-3">
							<HashLoader color={currentColor} />
						</div>
					) : (
						<GridComponent
							width="100%"
							ref={gridRef}
							dataSource={publishersData}
							enableHover={true}
							allowPaging
							pageSettings={{ pageCount: 5 }}
							toolbar={toolbarOptions}
							allowSorting={true}
							allowMultiSorting={true}
							rowSelected={handleRowClick}
						>
							<ColumnsDirective>
								{/* <ColumnDirective className="e-checklist" type="checkbox" width="50" /> */}
								<ColumnDirective field="emailId" headerText="Email" width="150" />
								<ColumnDirective field="name" headerText="Name" width="120" />
								{userType === "Admin" && (
									<ColumnDirective
										field="employeeId"
										headerText="Employee Name"
										template={(rowData) => (
											<span>
												{rowData.employeeId.firstName}{" "}
												{rowData.employeeId.lastName}
											</span>
										)}
										width="120"
									/>
								)}
								<ColumnDirective
									field="uuid.active"
									headerText="Deactive / Active"
									template={actionsTemplate}
									width="100"
								/>
								{userType && userType === "Admin" ? (
									<ColumnDirective
										headerText="Actions"
										template={actionsTemplate1}
										width="190"
									/>
								) : (
									<></>
								)}
							</ColumnsDirective>

							<Inject
								services={[Page, Selection, Toolbar, Edit, Sort, Search]}
							/>
							{/* <Search placeholder="Search by ID, Name, or Employee Name" /> */}
						</GridComponent>
					)}
					{document.getElementById("grid_211678832_1_searchbar")
						? (document.getElementById(
								"grid_211678832_1_searchbar"
						  ).placeholder = "Search By Name")
						: console.log("no search")}
				</div>
				<Modal fetchData={fetchData} />

				<ChangeEmployeeContract
					modal={modal1}
					setModal={setModal1}
					pubId={pubId}
					fetchData={fetchData}
				/>
			</>
		);
	}
};

export default Publishers;
