import React, { useState, useEffect } from "react";
import axios from "axios";
import dateFormat from "../../Utils/dateFormatter";
import { useStateContext } from "../../contexts/ContextProvider";
import handleResponse from "../../Utils/responseHandler";
import { useNavigate } from "react-router-dom";
const ContractTableView = (props) => {
	const [formData, setFormData] = useState({});
	const { currentColor } = useStateContext();
	const Navigate = useNavigate();
	const fetchContract = () => {
		axios
			.get(`/api/contract/get/one/${props.contractId}`, {
				headers: {
					Authorization: "Bearer " + sessionStorage.getItem("Token"),
				},
			})
			.then((response) => response.data)
			.then((data) => {
				data.startDate = dateFormat(data.startDate);
				data.endDate = dateFormat(data.endDate);
				data.tnc = data.tnc.split("\n");
				setFormData(data);
			})
			.catch((error) => {
				handleResponse(error, Navigate);
				console.log(error);
			});
	};
	useEffect(() => {
		fetchContract();
	}, [props.contractId]);

	return (
		<div className="flex items-center justify-center">
			<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl w-3/4 text-white ">
				<h1 className="px-6 py-4">
					{formData ? formData.contractCreationDate : "Date Not Found"}
				</h1>
				<h1
					style={{ backgroundColor: currentColor }}
					className="text-xl my-4 bg-gray-200 p-4 rounded-xl"
				>
					General Information
				</h1>
				<div className="relative overflow-x-auto">
					<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
						<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
							<tr className="bg-white dark:bg-gray-800">
								<th className="px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"></th>
								<th className="px-6 py-4 text-lg w-1/3">Advertiser</th>
								<th className="px-6 py-4 text-lg w-1/3">Publisher</th>
							</tr>
						</thead>
						<tbody>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white "
								>
									Company Name
								</th>
								<td className="px-6 py-4">
									{formData
										? formData.companyName
										: "Company Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.pubCompanyName
										: "Company Details Not Found"}
								</td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Street Address
								</th>
								<td className="px-6 py-4">
									{formData
										? formData.streetAddress
										: "Street Address Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.pubStreetAddress
										: "Street Address Details Not Found"}
								</td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									City/State/Zip
								</th>
								<td className="px-6 py-4">
									{formData ? formData.cityStateZip : "State Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.pubCityStateZip
										: "State Details Not Found"}
								</td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Country
								</th>
								<td className="px-6 py-4">
									{formData ? formData.country : "Country Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData ? formData.pubCountry : "Country Details Not Found"}
								</td>
							</tr>

							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Pan Number
								</th>
								<td className="px-6 py-4">
									{formData && formData.panNumber ? formData.panNumber : "-"}
								</td>
								<td className="px-6 py-4">
									{formData && formData.pubPanNumber
										? formData.pubPanNumber
										: "-"}
								</td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									CIN Number
								</th>
								<td className="px-6 py-4">
									{formData && formData.cinNumber ? formData.cinNumber : "-"}
								</td>
								<td className="px-6 py-4">
									{formData && formData.pubCinNumber
										? formData.pubCinNumber
										: "-"}
								</td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Goods & Service Tax No.
								</th>
								<td className="px-6 py-4">
									{formData && formData.gstNumber ? formData.gstNumber : "-"}
								</td>
								<td className="px-6 py-4">
									{formData && formData.pubGstNumber
										? formData.pubGstNumber
										: "-"}
								</td>
							</tr>

							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									VAT/Tax ID
								</th>
								<td className="px-6 py-4">
									{formData && formData.taxID ? formData.taxID : "-"}
								</td>
								<td className="px-6 py-4">
									{formData && formData.pubTaxID ? formData.pubTaxID : "-"}
								</td>
							</tr>

							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									URL
								</th>
								<td className="px-6 py-4">
									{formData ? formData.url : "URL Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData ? formData.pubUrl : "URL Details Not Found"}
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h1
					style={{ backgroundColor: currentColor }}
					className="text-white text-xl my-4 bg-gray-200 p-4 rounded-xl"
				>
					Contact Information
				</h1>
				<div className="relative overflow-x-auto">
					<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
						<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
							<tr className="bg-white dark:bg-gray-800">
								<th
									scope="row"
									className="px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								></th>
								<th className="px-6 py-4 text-lg w-1/3">Advertiser</th>
								<th className="px-6 py-4 text-lg w-1/3">Publisher</th>
							</tr>
						</thead>
						<tbody>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Sales contact name
								</th>
								<td className="px-6 py-4">
									{formData
										? formData.salesContactName
										: "Sales Contact Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.pubSalesContactName
										: "Sales Contact Not Found"}
								</td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Sales contact email
								</th>
								<td className="px-6 py-4">
									{formData
										? formData.salesContactEmail
										: "Sales Email Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.pubSalesContactEmail
										: "Sales Email Not Found"}
								</td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Sales contact phone
								</th>
								<td className="px-6 py-4">
									{formData
										? formData.salesContactPhone
										: "Sales Contact Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.pubSalesContactPhone
										: "Sales Contact Not Found"}
								</td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Finance contact name
								</th>
								<td className="px-6 py-4">
									{formData
										? formData.financeContactName
										: "Names Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.pubFinanceContactName
										: "Name Details Not Found"}
								</td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Finance contact email
								</th>
								<td className="px-6 py-4">
									{formData
										? formData.financeContactEmail
										: "Email Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.pubFinanceContactEmail
										: "Email Details Not Found"}
								</td>
							</tr>

							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Finance contact phone
								</th>
								<td className="px-6 py-4">
									{formData
										? formData.financeContactPhone
										: "Ph no. Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.pubFinanceContactPhone
										: "Ph no. Details Not Found"}
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h1
					style={{ backgroundColor: currentColor }}
					className=" text-white text-xl my-4 bg-gray-200 p-4 rounded-xl"
				>
					Campaign Information
				</h1>
				<div className="relative overflow-x-auto">
					<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
						<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
							<tr className="bg-white dark:bg-gray-800">
								<th
									scope="row"
									className="px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Description
								</th>
								<th className="px-6 py-4">Start Date</th>
								<th className="px-6 py-4">End Date</th>
								<th className="px-6 py-4">Daily Limit</th>
								<th className="px-6 py-4">Ad Sizes</th>
								<th className="px-6 py-4">Geo-Targeting</th>
								<th className="px-6 py-4">URLs</th>
							</tr>
						</thead>
						<tbody>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<td className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white">
									{formData ? formData.description : "Description Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.startDate
										: "Start Date Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData ? formData.endDate : "End Date Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.dailyLimit
										: "Daily Limit Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData ? formData.adSizes : "Ad Sizes Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData ? formData.geoTargeting : "Geo Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.campaignUrl
										: "Campaign URL's  Details Not Found"}
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h1
					style={{ backgroundColor: currentColor }}
					className="text-white text-xl my-4 bg-gray-200 p-4 rounded-xl"
				>
					Terms Information
				</h1>
				<div className="relative overflow-x-auto">
					<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
						<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
							<tr className="bg-white dark:bg-gray-800">
								<th
									scope="row"
									className="px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Deal Type
								</th>
								<th className="px-6 py-4">Rate</th>
								<th className="px-6 py-4">Billing Source</th>
								<th className="px-6 py-4">Payment Terms</th>
								<th className="px-6 py-4">Cancellation Period</th>
								<th className="px-6 py-4">Total Budget</th>
							</tr>
						</thead>
						<tbody>
							<tr className="bg-white dark:bg-gray-800">
								<td className="px-6 py-4">
									{formData ? formData.dealType : "Deal Type Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData ? formData.rate : "Rate Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.billingSource
										: "Billing Source Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.paymentTerms
										: "Payment Terms Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData ? formData.period : "Cancellation Period Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.totalBudget
										: "Total Budget  Details Not Found"}
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h1
					style={{ backgroundColor: currentColor }}
					className="text-white text-xl my-4 bg-gray-200 p-4 rounded-xl "
				>
					Special Instructions
				</h1>
				<div className="text-md text-gray-700  my-4 p-4 rounded-xl">
					{formData && formData.comments
						? formData.comments
						: "Comments  Details Not Found"}
				</div>

				<h1
					style={{ backgroundColor: currentColor }}
					className="text-white text-xl my-4 bg-gray-200 p-4 rounded-xl"
				>
					Terms and Conditions
				</h1>
				<ul className="text-md my-4 p-4 rounded-xl text-gray-700 ">
					{formData && formData.tnc
						? formData.tnc.map((item, index) => (
								<li key={index} className="text-gray-700 ">
									{item}
								</li>
						  ))
						: "TNC Details Not Found"}
				</ul>

				<div className="relative overflow-x-auto">
					<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
						<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
							<tr className="bg-white dark:bg-gray-800">
								<th scope="row" className="px-6 py-4 text-lg"></th>
								<th className="px-6 py-4 text-lg">Advertiser</th>
								<th scope="row" className="px-6 py-4 text-lg">
									Publisher
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<th
									scope="row"
									className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
								>
									Company Name
								</th>
								<td className="px-6 py-4">
									{formData ? formData.companyName : "Name Details Not Found"}
								</td>
								<td className="px-6 py-4">
									{formData
										? formData.pubCompanyName
										: "Name Details Not Found"}
								</td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<td className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white">
									Name:
								</td>
								<td className="px-6 py-4"></td>
								<td className="px-6 py-4"></td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<td className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white">
									Designation
								</td>
								<td className="px-6 py-4">Co-Founder</td>
								<td className="px-6 py-4"></td>
							</tr>
							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
								<td className="uppercase px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white">
									Signature:
								</td>
								<td className="px-6 py-4"></td>
								<td className="px-6 py-4"></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default ContractTableView;
