import React, { useState, useEffect } from "react";
import axios from "axios";
import { showErrorToast } from "../../Utils/toastUtils";

const Logs = () => {
	const [logs, setLogs] = useState([]);
	const [loading, setLoading] = useState(false);
	const fetchData = async () => {
		try {
			setLoading(true);
			const result = await axios.get("/api/logs/dash", {
				headers: {
					Authorization: "Bearer " + sessionStorage.getItem("Token"),
				},
			});
			setLogs(result.data.reverse());
		} catch (error) {
			showErrorToast("Error!");
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className="step-three flex items-center justify-center h-full mx-auto px-4 mt-5 w-1/2 ">
			<div className="flex-1 bg-red-50 rounded-lg shadow-xl mt-4 p-8 w-50 animate-slide-in-1 ">
				<div className="flex flex-row items-center justify-between">
					<h4 className="text-xl text-gray-900 font-bold">Activity log</h4>
				</div>
				<div className="relative px-4 overflow-y-auto">
					{logs ? (
						logs.map((item, index) => {
							return (
								<div
									key={index}
									className="flex items-center w-full my-6 -ml-1.5"
								>
									<div className="w-1/12 z-10">
										<div className="w-3.5 h-3.5 bg-blue-600 rounded-full"></div>
									</div>
									<div className="w-11/12">
										<p className="text-sm">{item.message}</p>
										<p className="text-xs text-gray-500">
											{new Date(item.date).toLocaleDateString() +
												" " +
												new Date(item.date).toLocaleTimeString()}
										</p>
									</div>
								</div>
							);
						})
					) : (
						<div className="flex items-center w-full my-6 text-bold -ml-1.5">
							Logs Going To Appear Soon...
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default Logs;
