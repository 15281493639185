import React, { useEffect, useState } from "react";
import {
	GridComponent,
	Inject,
	ColumnsDirective,
	ColumnDirective,
	Search,
	Page,
} from "@syncfusion/ej2-react-grids";
import axios from "axios";
import { Header } from "../components";
import { useNavigate } from "react-router-dom";
import NewSalesModal from "../modals/addSales";
import { useStateContext } from "../contexts/ContextProvider";
import HashLoader from "react-spinners/HashLoader";

export const SalesContext = React.createContext();

const Sales = () => {
	const { currentColor } = useStateContext();
	// const toolbarOptions = ['Search'];
	const [modal, setModal] = useState(false);
	const [rows, setRows] = useState([]);
	const navigate = useNavigate();
	const userType = sessionStorage.getItem("role");
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		getData();
	}, [currentColor]);

	const editing = { allowDeleting: true, allowEditing: true };

	const getData = () => {
		setLoading(true);
		axios
			.get("/api/sales/all", {
				headers: {
					Authorization: "Bearer " + sessionStorage.getItem("Token"),
				},
			})
			.then((response) => {
				setRows(response.data);
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleRowClick = (args) => {
		const targetElement = args.target;

		if (targetElement.classList.contains("btn-edit-2")) {
			navigate(`/advertisers/${args.data._id}/edit`);
		} else if (targetElement.classList.contains("btn-doc")) {
			navigate(`/advertisers/docs/${args.data._id}`);
		} else {
			const selectedUserId = args.data._id;
			navigate(`/advertisers/${selectedUserId}`);
		}
	};

	const creationDateTempalate = (args) => {
		return (
			<div>
				<div>{args ? new Date(args.creation).toDateString() : "No Date"}</div>
			</div>
		);
	};

	const actionsTemplate = (args) => {
		return (
			<div className="btn-edit-2">
				<label className="relative inline-flex items-center cursor-pointer btn-edit-2">
					<button
						className="btn-edit-2 text-white font-bold py-2 px-4 rounded"
						style={{ background: currentColor }}
						onClick={(e, args) => {
							e.preventDefault();
						}}
					>
						Edit
					</button>
				</label>
			</div>
		);
	};

	const docTemplate = (args) => {
		return (
			<div>
				<button
					style={{ background: currentColor }}
					onClick={(e, args) => {
						e.preventDefault();
					}}
					className="btn-doc text-white font-bold py-2 px-4 rounded"
				>
					Get Documents
				</button>
			</div>
		);
	};

	if (userType !== "Publisher") {
		return (
			<>
				<div className="m-2 md:my-10 mt-24 p-2 md:p-10 bg-white mid:max-w-xl semi-mid:mx-10 semi-mid:min-w-fit rounded-3xl mx-auto">
					<Header category="Page" title="Advertiser" />
					{loading ? (
						<div class="flex justify-center m-3">
							<HashLoader color={currentColor} />
						</div>
					) : (
						<GridComponent
							width="100%"
							id="userGrid"
							dataSource={rows}
							allowPaging
							allowSorting
							pageSettings={{ pageCount: 5 }}
							editSettings={editing}
							// toolbar={toolbarOptions}
							rowSelected={handleRowClick}
						>
							<ColumnsDirective>
								{/* <ColumnDirective type='checkbox' width='50' /> */}
								<ColumnDirective
									field="salesCompanyName"
									headerText="Sales Company Name"
									width="120"
								/>
								<ColumnDirective
									field="companyName"
									headerText="Company Name"
									width="120"
								/>
								<ColumnDirective
									field="creation"
									template={creationDateTempalate}
									width="120"
								/>
								<ColumnDirective
									headerText="Actions"
									template={actionsTemplate}
									width="170"
								/>
								<ColumnDirective
									headerText="Documents"
									template={docTemplate}
									width="170"
								/>
							</ColumnsDirective>
							<Inject services={[Search, Page]} />
						</GridComponent>
					)}
					<div
						style={{
							float: "right",
							marginRight: "20px",
							paddingTop: "15px",
						}}
					>
						<div
							className=" flex justify-end px-3"
							style={{
								position: "fixed",
								bottom: 0,
								left: 0,
								right: 0,
								zIndex: 9999,
								padding: "10px",
							}}
						>
							{(userType === "Employee" || userType === "Admin") && (
								<button
									style={{ background: currentColor }}
									className="
                fixed bottom-5 right-24 bg-blue-500 hover:drop-shadow-xl text-white
                font-bold py-2 px-4 rounded transition duration-300 ease-in-out shadow-lg
              "
									onClick={() => setModal(!modal)}
								>
									Add Advertiser
								</button>
							)}
						</div>
					</div>

					<SalesContext.Provider value={{ modal, setModal, getData }}>
						<NewSalesModal
							getData={getData}
							modal={modal}
							setModal={setModal}
						/>
					</SalesContext.Provider>
				</div>
			</>
		);
	}
};

export default Sales;
