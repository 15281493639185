import React, { useEffect, useState } from "react";
import {
	GridComponent,
	ColumnsDirective,
	ColumnDirective,
	Page,
	Selection,
	Inject,
	Edit,
	Toolbar,
	Sort,
} from "@syncfusion/ej2-react-grids";
import axios from "axios";
import { Header } from "../components";
import { useNavigate } from "react-router-dom";
import AddNewSubPublisher from "../modals/AddNewSubPublisher";
import { useStateContext } from "../contexts/ContextProvider";
import HashLoader from "react-spinners/HashLoader";

const SubPublisherDetails = () => {
	const { currentColor } = useStateContext();
	const [modal, setModal] = useState(false);
	const [rows, setRows] = useState([]);
	const navigate = useNavigate();
	const userType = sessionStorage.getItem("role");
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		getData();
	}, []);

	const getData = () => {
		setLoading(true);
		axios
			.get("/api/subPublisher/get/6593e47c558d52d2cd3b0ee6", {
				headers: {
					Authorization: "Bearer " + sessionStorage.getItem("Token"),
				},
			})
			.then((response) => {
				setRows(response.data);
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleRowClick = (args) => {
		const targetElement = args.target;
		if (targetElement.classList.contains("btn-edit")) {
			handleActiveChange(args);
		} else {
			if (args.data.active === true) {
				const selectedUserId = args.data._id;
				navigate(`/employees/${selectedUserId}`);
			} else {
				navigate("/employees");
			}
		}
	};

	const actionsTemplate = (args) => {
        const temp =  args && args.uuid && args.uuid.active ? args.uuid.active :false
		return (
			<div>
				<label
					className="relative inline-flex items-center cursor-pointer btn-edit"
					onChange={() => {
						handleActiveChange(args);
					}}
				>
					<input
						type="checkbox"
						checked={temp}
						className="sr-only peer btn-edit"
					/>
					<div
						className={`btn-edit w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 `}
					></div>
				</label>
			</div>
		);
	};


	const roleTemplate = (args) => {
        console.log(args)
		return (
			<div>
                {args.permission? "Edit " :"View"}
			</div>
		);
	};

	const handleActiveChange = async (args) => {
        console.log(args);
		try {
			const result = await axios
				.put(
					`/api/subPublisher/active/${args.uuid._id}`,
					{ active: !args.uuid.active },
					{
						headers: {
							Authorization: "Bearer " + sessionStorage.getItem("Token"),
						},
					}
				)
				.then(() => {
					args.uuid.active = !args.uuid.active;
					setRows([...rows]);
                    // getData()
				});
		} catch (error) {
			console.log(error);
		}
	};

	if (userType === "Publisher") {
		return (
			<>
				<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
					<Header category="Page" title="Publisher Internal Accounts" />
					{loading ? (
						<div class="flex justify-center m-3">
							<HashLoader color={currentColor} />
						</div>
					) : (
						<GridComponent
							id="userGrid"
							dataSource={rows}
							width="auto"
							allowPaging
							allowSorting
							pageSettings={{ pageCount: 5 }}
							// rowSelected={handleRowClick}
						>
							<ColumnsDirective>
								<ColumnDirective field="emailId" headerText="Username" />
                                <ColumnDirective
                                    headerText="Roles"
                                    template={roleTemplate}
                                />
								{userType && userType === "Publisher" ? (
									<ColumnDirective
										headerText="Actions"
										template={actionsTemplate}
									/>
								) : (
									<></>
								)}
							</ColumnsDirective>
							{/* <Inject services={[Search, Page]} /> */}
							<Inject
								services={[Page, Selection, Toolbar, Edit, Sort]}
							/>
						</GridComponent>
					)}
					<div
						style={{
							float: "right",
							marginRight: "20px",
							paddingTop: "15px",
						}}
					>
						<div
							className=" flex justify-end px-3"
							style={{
								position: "fixed",
								bottom: 0,
								left: 0,
								right: 0,
								zIndex: 9999,
								padding: "10px",
							}}
						>
							{userType && userType === "Publisher" ? (
								<button
									style={{ background: currentColor }}
									className="
                fixed bottom-5 right-24 bg-blue-500 hover:drop-shadow-xl text-white
                font-bold py-2 px-4 rounded transition duration-300 ease-in-out shadow-lg
              "
									onClick={() => setModal(!modal)}
								>
									Add Sub Publisher
								</button>
							) : (
								<></>
							)}
						</div>
					</div>

					<AddNewSubPublisher getData={getData} modal={modal} setModal={setModal} />
				</div>
			</>
		);
	} else {
		return <></>;
	}
};

export default SubPublisherDetails;
