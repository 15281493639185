import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import handleResponse from "../Utils/responseHandler";

import { Header } from "../components";
import {
	GridComponent,
	ColumnsDirective,
	ColumnDirective,
	Page,
	Selection,
	Inject,
	Edit,
	Toolbar,
	Sort,
	Search,
} from "@syncfusion/ej2-react-grids";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import HashLoader from "react-spinners/HashLoader";

const EachUser = () => {
	const [userData, setUserData] = useState(null);
	const [pubData, setPubData] = useState(null);
	const [employeeId, setEmployeeId] = useState(null);
	const navigate = useNavigate();
	const gridRef = useRef(null);
	const editing = { allowDeleting: true, allowEditing: true };
	// const toolbarOptions = ['Search'];
	const [loading, setLoading] = React.useState(false);

	const { currentColor } = useStateContext();

	let { id } = useParams();

	const handleButtonClick = () => {
		navigate(`/employee/docs/${employeeId}`);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				const result = await axios.get(
					`/api/employee/details/${id}`,
					{
						headers: {
							Authorization: "Bearer " + sessionStorage.getItem("Token"),
						},
					}
				);
				setEmployeeId(result.data._id);
				setUserData(result.data);
			} catch (error) {
				handleResponse(error, navigate);
				console.log(error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [id]);

	const fetchData = async () => {
		try {
			setLoading(true);
			const result = await axios.get(
				`/api/publisher/get/${id}`,
				{
					headers: {
						Authorization: "Bearer " + sessionStorage.getItem("Token"),
					},
				}
			);
			setPubData(result.data);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchData();
	}, [id]);

	const handleRowClick = (args) => {
		let selectedPublisherId = args.data._id;
		navigate(`/publisher/${selectedPublisherId}`);
	};

	return (
		<>
			<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
				{userData ? (
					<>
						<div className="flex justify-between items-center ">
							<div>
								<Header
									category="Page"
									title={userData.firstName + " " + userData.lastName}
								/>
							</div>

							<button
								style={{ background: currentColor }}
								onClick={handleButtonClick}
								className=" text-white font-bold py-2 px-4 rounded"
							>
								Documents
							</button>
						</div>
						<div className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
							<div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
								<div className="flex space-x-4">
									<div className="w-1/2">
										<h2 className="text-sm font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2">
											Employee ID: {userData._id}
										</h2>
									</div>
									<div className="w-1/2">
										<h2 className="text-sm font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2">
											Name: {userData.firstName + " " + userData.lastName}
										</h2>
									</div>
								</div>
								<div className="flex space-x-4">
									<div className="w-1/2">
										<h2 className="text-sm font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2">
											Email ID: {userData.emailId}
										</h2>
									</div>
									<div className="w-1/2">
										<h2 className="text-sm font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2">
											Phone Number: {userData.ph}
										</h2>
									</div>
								</div>
								<div className="flex space-x-4">
									<div className="w-1/2">
										<h2 className="text-sm font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2">
											PAN Number: {userData.pan}
										</h2>
									</div>
									<div className="w-1/2">
										<h2 className="text-sm font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2">
											Aadhar Number: {userData.aadhar}
										</h2>
									</div>
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<Header category="Page" title="User Info" />
						{loading && (
							<div class="flex justify-center m-3">
								<HashLoader color={currentColor} />
							</div>
						)}
					</>
				)}
			</div>

			<div className="m-2 md:m-10 mt-10 p-2 md:p-10 bg-white rounded-3xl">
				{pubData ? (
					<>
						<Header category="Page" title="Publishers" />

						<GridComponent
							ref={gridRef}
							dataSource={pubData}
							enableHover={true}
							allowPaging
							pageSettings={{ pageCount: 5 }}
							editSettings={editing}
							// toolbar={toolbarOptions}
							allowSorting={true}
							allowMultiSorting={true}
							rowSelected={handleRowClick}
						>
							<ColumnsDirective>
								<ColumnDirective field="_id" headerText="ID" />
								<ColumnDirective field="name" headerText="Name" />
							</ColumnsDirective>

							<Inject
								services={[Page, Selection, Toolbar, Edit, Sort, Search]}
							/>
						</GridComponent>
					</>
				) : (
					<>
						<Header category="Page" title="Publishers" />
						{loading && (
							<div class="flex justify-center m-3">
								<HashLoader color={currentColor} />
							</div>
						)}
					</>
				)}
			</div>
		</>
	);
};

export default EachUser;
