import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { showSuccessToast, showErrorToast } from "../Utils/toastUtils";
import Modal from "./modal";
import { countries } from "../data/dummy";

const NewComanyModal = (props) => {
  const { register, handleSubmit } = useForm();
  const fetchData = props.fetchData;
  const modalOpen = props.modalOpen;
  const setModalOpen = props.setModalOpen;
  const [publisherName, setPublisherName] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [otherCountry, setOtherCountry] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const handleColorChange = (event) => {
    const color = event.target.value;
    setSelectedColor(event.target.value);
  };

  const handleInputChange = (event) => {
    const color = event.target.value;
    setSelectedColor(event.target.value);
  };

  const onSubmit = (data) => {
    setIsProcessing(true);
    axios
      .post("/api/company/create", data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("Token"),
        },
      })
      .then((response) => {
        showSuccessToast(
          response.data && response.data.Message
            ? response.data.Message
            : "Success"
        );
        setModalOpen(false);
        fetchData();
      })
      .catch((err) => {
        console.log(err);
        setIsProcessing(false);
        showErrorToast("Registration Error!");
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const closeModal = () => {
    setPublisherName("");
    setSelectedCompany("");
    setModalOpen(false);
  };

  const handleCountryChange = (event) => {
    event.preventDefault();
    setOtherCountry("");
    setSelectedCountry(event.target.value);
  };

  const domains = [
    {
      name: "General Info",
      fields: [
        { label: "Company Name", type: "text", name: "companyName" },
        { label: "Street Address", type: "text", name: "streetAddress" },
        { label: "City/State/Zip", type: "text", name: "cityStateZip" },
        { label: "Country", type: "text", name: "country" },
        {
          label: "Pan Number",
          type: "text",
          name: "panNumber",
          isDisabled: selectedCountry === "India" ? false : true,
        },
        {
          label: "CIN Number",
          type: "text",
          name: "cinNumber",
          isDisabled: selectedCountry === "India" ? false : true,
        },
        {
          label: "Goods & Service Tax No.",
          type: "text",
          name: "gstNumber",
          isDisabled: selectedCountry === "India" ? false : true,
        },
        {
          label: "TAX / VAT NO.",
          type: "text",
          name: "pubTaxID",
          isDisabled: selectedCountry === "India" ? true : false,
        },
        { label: "URL", type: "text", name: "url" },
        {
          label: "Finance Contact Name",
          type: "text",
          name: "financeContactName",
        },
        {
          label: "Finance Contact Email",
          type: "email",
          name: "financeContactEmail",
        },
        {
          label: "Finance Contact Phone",
          type: "tel",
          name: "financeContactPhone",
        },
        { label: "Login Template ID", type: "text", name: "loginTempID" },
        { label: "Reset Template ID", type: "text", name: "resetTempID" },
        { label: "Contract Template ID", type: "text", name: "contractTempID" },
        { label: "Company Logo", type: "text", name: "logo" },
      ],
    },
  ];

  return (
    <div>
      <Modal isOpen={modalOpen} onClose={closeModal} className="mt-12">
        <div className="flex items-center justify-center">
          <div className="bg-white w-full max-w-3xl mx-auto p-8 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-6 text-center rounded-lg">
              Create New Company
            </h3>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="max-w-3xl mx-auto p-8 bg-gray-100 rounded shadow-md"
            >
              {domains.map((domain) => (
                <div
                  key={domain.name}
                  className="mb-4 p-6 border rounded transition-colors duration-300 ease-in-out border-gray-300"
                >
                  <h2 className="text-xl font-bold mb-4">{domain.name}</h2>
                  {domain.fields.map((field) => (
                    <div
                      style={{
                        display: field.isDisabled ? "none" : "block",
                      }}
                      className="mb-4"
                      key={field.name}
                    >
                      {field.name === "country" && (
                        <div>
                          <label
                            htmlFor={field.name}
                            className="block font-medium mb-2"
                          >
                            {field.label}:
                          </label>
                          <select
                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                            id={field.name}
                            {...register(field.name)}
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            required
                          >
                            {countries.map((country) => (
                              <option key={country.name} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      {field.name !== "country" && (
                        <div>
                          <label
                            htmlFor={field.name}
                            className="block font-medium mb-2"
                          >
                            {field.label}:
                          </label>
                          <input
                            type={field.type}
                            disabled={field.isDisabled}
                            id={field.name}
                            {...register(field.name)}
                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                            required
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}

              <div className="mb-4 p-6 border rounded transition-colors duration-300 ease-in-out border-blue-500">
                <h2 className="text-xl font-bold mb-4">
                  Choose a Color for I/O Template{" "}
                </h2>
                <input
                  type="color"
                  value={selectedColor}
                  onChange={handleColorChange}
                  className="mr-3 rounded"
                />
                <input
                  type="text"
                  value={selectedColor}
                  onChange={handleInputChange}
                  {...register("tempColor")}
                />
              </div>

              <div className="mb-4 p-6 border rounded transition-colors duration-300 ease-in-out border-blue-500">
                <h2 className="text-xl font-bold mb-4">Terms and Conditions</h2>

                <div className="mt-2">
                  <textarea
                    id="tnc"
                    {...register("tnc")}
                    className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                    placeholder="Enter T&C"
                    required
                  ></textarea>
                </div>
              </div>
              <button
                type="submit"
                disabled={isProcessing}
                className={`${isProcessing
                    ? "bg-gray-500 hover:bg-gray-700"
                    : "bg-blue-500 hover:bg-blue-700"
                  } flex mx-auto  text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out`}
              >
                Submit
              </button>
            </form>
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              onClick={closeModal}
            >
              <svg
                className="w-6 h-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M13.41 12l4.29-4.29a1 1 0 1 0-1.41-1.41L12 10.59 7.71 6.29a1 1 0 0 0-1.41 1.41L10.59 12l-4.3 4.29a1 1 0 0 0 1.42 1.42L12 13.41l4.29 4.3a1 1 0 0 0 1.42-1.42L13.41 12z" />
              </svg>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NewComanyModal;
