import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Header } from "../components";
import { useNavigate } from "react-router-dom";
import ContractForm from "./Forms/contractshowForm";
import NewContractModal from "../modals/addContracts";
import { useStateContext } from "../contexts/ContextProvider";
import HashLoader from "react-spinners/HashLoader";
import handleResponse from "../Utils/responseHandler";

import {
	GridComponent,
	ColumnsDirective,
	ColumnDirective,
	Page,
	Selection,
	Inject,
	Edit,
	Toolbar,
} from "@syncfusion/ej2-react-grids";
import { showSuccessToast, showErrorToast } from "../Utils/toastUtils";

const PublisherDetails = () => {
	const [publisherData, setPublisherData] = useState(null);
	const [publisherId, setPublisherId] = useState(null);
	const [contractId, setContractId] = useState(null);
	const [contracts, setContracts] = useState([]);
	const [modal, setModal] = useState(false);
	const [loading, setLoading] = React.useState(false);
	const navigate = useNavigate();
	const UserType = sessionStorage.getItem("role") === "Publisher";
	const userType = sessionStorage.getItem("role");

	const selectionsettings = { persistSelection: true };
	// const toolbarOptions = ["Delete"];

	const { currentColor } = useStateContext();

	let { id } = useParams();
	const handleButtonClick = () => {
		navigate(`/publisher/docs/${id}`);
	};

	const handleRowClick = (args) => {
		const targetElement = args.target;
		if (targetElement.classList.contains("btn-edit")) {
			handleActiveChange(args);
		} else if (targetElement.classList.contains("btn-edit-2")) {
			navigate(`/publisher/contract/${args.data._id}/edit`);
		} else {
			handleContractClick(args.data._id);
		}
	};

	const handleContractClick = useCallback((clickedContractId) => {
		setContractId(clickedContractId);
		navigate(`/publisher/contract/${clickedContractId}`);
	}, []);

	const fetchData = async () => {
		try {
			setLoading(true);
			const result = await axios.get(
				`/api/publisher/getpubbasic/${id}`,
				{
					headers: {
						Authorization: "Bearer " + sessionStorage.getItem("Token"),
					},
				}
			);

			setPublisherId(result.data._id);
			setPublisherData(result.data);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const fetchContracts = async () => {
		try {
			const result = await axios.get(
				`/api/contract/get/all/${id}`,
				{
					headers: {
						Authorization: "Bearer " + sessionStorage.getItem("Token"),
					},
				}
			);

			setContracts(result.data);
		} catch (error) {
			handleResponse(error, navigate);
			console.log(error);
		}
	};
	useEffect(() => {
		fetchData();
		fetchContracts();
	}, [currentColor]);

	const handleActiveChange = async (args) => {
		try {
			const result = await axios.put(
				`/api/contract/contractActive/${args.data._id}`,
				{ isActive: !args.data.isActive },
				{
					headers: {
						Authorization: "Bearer " + sessionStorage.getItem("Token"),
					},
				}
			);
			fetchContracts();
		} catch (error) {
			console.log(error);
		}
	};

	const handleButtonClickpdf = async (e, args) => {
		e.stopPropagation();

		try {
			const response = await axios.get(
				`/api/contract/sendforapproval/${args._id}`,
				{
					headers: {
						Authorization: "Bearer " + sessionStorage.getItem("Token"),
					},
				}
			);

			showSuccessToast(response.data.Message);
			fetchContracts();
		} catch (error) {
			showErrorToast("Sending Approval Error!");
			console.error("Error occurred:", error);
		}
	};

	const approvalTemplate = (args) => {
		let content = null;
		if (args.approvalStatus === "Pending") {
			content = (
				<button
					className="flex mx-auto bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out"
					disabled
				>
					Pending
				</button>
			);
		} else if (args.isSubmitted === false) {
			content = (
				<button
					className="flex mx-auto bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out"
					disabled
				>
					Send for Approval
				</button>
			);
		} else if (args.approvalStatus === "Approved") {
			content = (
				<button
					style={{ background: currentColor }}
					className="flex mx-auto  text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out"
					// onClick={(e) => { handleButtonClickpdf(e, args) }}
					disabled
				>
					Signed
				</button>
			);
		} else {
			content = (
				<button
					style={{ background: currentColor }}
					className="flex mx-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out"
					disabled={!args.isSubmitted}
					onClick={(e) => {
						handleButtonClickpdf(e, args);
					}}
				>
					Send for Approval
				</button>
			);
		}

		return (
			<div>
				<label className="relative inline-flex items-center cursor-pointer">
					<button>{content}</button>
				</label>
			</div>
		);
	};

	const activeTempalate = (args) => {
		return (
			<div className="flex flex-row items-center ">
				<label
					className="relative inline-flex items-center justify-center cursor-pointer btn-edit"
					onChange={() => handleActiveChange(args)}
				>
					<input
						type="checkbox"
						checked={args.isActive}
						className=" h-100 sr-only justify-center peer btn-edit"
					/>
					<div
						className={`btn-edit justify-center w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 `}
					></div>
				</label>
				<label className=" relative inline-flex items-center justify-center cursor-pointer ml-2 btn-edit-2">
					<button
						className="btn-edit-2 text-white font-bold py-2 px-4 rounded"
						style={{ background: currentColor }}
						onClick={(e, args) => {
							e.stopProppogation();
							e.preventDefault();
						}}
					>
						Edit
					</button>
				</label>
			</div>
		);
	};

	const startDateTempalate = (args) => {
		return (
			<div>
				<div>{args ? new Date(args.startDate).toDateString() : "No Date"}</div>
			</div>
		);
	};
	const isSubmmitedTemplate = (args) => {
		return (
			<div className="text-center flex items-center justify-center w-1/2">
				{args.isSubmitted ? (
					<div className="">
						<div className="h-4 w-4 flex justify-center rounded-full bg-green-500 opacity-50"></div>
					</div>
				) : (
					<div className="h-4 w-4 items-center rounded-full bg-yellow-500 opacity-50"></div>
				)}
			</div>
		);
	};

	return (
		<>
			<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
				{publisherData ? (
					<>
						<Header category="Page" title={publisherData.name} />

						<div className="flex justify-between items-center ">
							<div>
								<h1 className="text-xl">Name: {publisherData?.name}</h1>
								<h1 className="text-xl">Email: {publisherData?.emailId}</h1>
								<h1 className="text-sm">ID: {publisherData._id}</h1>
							</div>

							<button
								style={{ background: currentColor }}
								onClick={handleButtonClick}
								className=" text-white font-bold py-2 px-4 rounded"
							>
								Get Documents
							</button>
						</div>
					</>
				) : (
					<>
						<Header category="Page" title="Publisher Info" />
						{loading && (
							<div class="flex justify-center m-3">
								<HashLoader color={currentColor} />
							</div>
						)}
					</>
				)}

				{contracts && (
					<div className="flex flex-col justify-center items-center">
						<div className="mt-10 text-lg font-medium  flex flex-col w-full  ">
							<div className=" overflow-auto	">
								{loading ? (
									<div class="flex justify-center m-3">
										<HashLoader color={currentColor} />
									</div>
								) : (
									<GridComponent
										width="100%"
										dataSource={contracts}
										enableHover={true}
										allowPaging
										pageSettings={{ pageCount: 5 }}
										selectionSettings={selectionsettings}
										// toolbar={toolbarOptions}
										allowSorting
										rowSelected={handleRowClick}
										columns={[
											{ field: "_id", headerText: "ID", isPrimaryKey: true },
											{ field: "publisherId.name", headerText: "Name" },
											{ field: "startDate", headerText: "Start Date" },
											{ field: "period", headerText: "Period" },
											{ field: "isSubmitted", headerText: "isSubmitted" },
										]}
									>
										<ColumnsDirective>
											<ColumnDirective
												field="_id"
												headerText="ID"
												width="150"
											/>
											<ColumnDirective
												field="publisherId.name"
												headerText="Name"
												width="120"
											/>
											<ColumnDirective
												field="Start Date"
												template={startDateTempalate}
												width="120"
											/>
											<ColumnDirective
												field="End Date"
												template={startDateTempalate}
												width="120"
											/>

											{!UserType && (
												<ColumnDirective
													field="Actions"
													template={activeTempalate}
													width="170"
												/>
											)}
											{userType === "Employee" && (
												<ColumnDirective
													field="Approval"
													template={approvalTemplate}
													width="170"
												/>
											)}
											{!UserType && (
												<ColumnDirective
													field="isSubmitted"
													template={isSubmmitedTemplate}
													width="120"
												/>
											)}
										</ColumnsDirective>
										<Inject services={[Page, Selection, Toolbar, Edit]} />
									</GridComponent>
								)}
							</div>

							<div>
								{contractId && (
									<div>
										<ContractForm contractId={contractId} />
									</div>
								)}
							</div>
						</div>
					</div>
				)}
				{userType === "Employee" && (
					<button
						style={{ background: currentColor }}
						className={`
                fixed bottom-5  right-24 hover:drop-shadow-xl text-white
                font-bold py-2 px-4 rounded transition duration-300 ease-in-out shadow-lg
              `}
						onClick={() => setModal(!modal)}
					>
						Add Contracts
					</button>
				)}
			</div>
			<NewContractModal
				publisherId={publisherId}
				modal={modal}
				setModal={setModal}
				fetchData={fetchData}
				fetchContracts={fetchContracts}
			/>
		</>
	);
};

export default PublisherDetails;
