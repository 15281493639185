import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { showSuccessToast, showErrorToast } from "../../Utils/toastUtils";
import { useNavigate } from "react-router-dom";
import { countries } from "../../data/dummy";

const Form = (props) => {
	const { register, handleSubmit, setValue } = useForm();
	const [selectedDomain, setSelectedDomain] = useState("");
	const [tnc, setTnc] = useState("");
	const [formData, setFormData] = useState({});
	const navigate = useNavigate();
	const [selectedCountry, setSelectedCountry] = useState("");
	const [otherCountry, setOtherCountry] = useState("");
	const [isProcessing, setIsProcessing] = useState(false);

	const companyData = async () => {
		axios
			.get(`/api/company/get/one/${props.companyId}`, {
				headers: {
					Authorization: "Bearer " + sessionStorage.getItem("Token"),
				},
			})
			.then((response) => response.data)
			.then((data) => {
				setSelectedCountry(data.country);
				setFormData(data);
				setTnc(data.tnc);
				populateFormFields(data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const compEmpData = async () => {
		axios
			.get(`/api/employee/emp`, {
				headers: {
					Authorization: "Bearer " + sessionStorage.getItem("Token"),
				},
			})
			.then((response) => response.data)
			.then((data) => {
				setFormData(data);
				populateFormFields(data);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	useEffect(() => {
		companyData();
		compEmpData();
	}, []);

	const handleCountryChange = (event) => {
		event.preventDefault();
		setOtherCountry("");
		setSelectedCountry(event.target.value);
	};

	const populateFormFields = (data) => {
		Object.keys(data).forEach((fieldName) => {
			const field = domains
				.flatMap((domain) => domain.fields)
				.find((f) => f.name === fieldName);
			if (field) {
				setValue(fieldName, data[fieldName]);
			}
		});
	};

	useEffect(() => {
		setValue("tnc", tnc);
	}, [tnc, setValue]);

	const domains = [
		{
			name: "General Info",
			fields: [
				{ label: "Company Name", type: "text", name: "companyName" },
				{ label: "Street Address", type: "text", name: "streetAddress" },
				{ label: "City/State/Zip", type: "text", name: "cityStateZip" },
				{ label: "Country", type: "text", name: "country" },
				{
					label: "Pan Number",
					type: "text",
					name: "panNumber",
					isDisabled: selectedCountry === "India" ? false : true,
				},
				{
					label: "CIN Number",
					type: "text",
					name: "cinNumber",
					isDisabled: selectedCountry === "India" ? false : true,
				},
				{
					label: "Goods & Service Tax No.",
					type: "text",
					name: "gstNumber",
					isDisabled: selectedCountry === "India" ? false : true,
				},
				{
					label: "TAX / VAT NO.",
					type: "text",
					name: "taxID",
					isDisabled: selectedCountry === "India" ? true : false,
				},
				{ label: "URL", type: "text", name: "url" },
				{ label: "Login Template ID", type: "text", name: "loginTempID" },
				{ label: "Reset Template ID", type: "text", name: "resetTempID" },
				{ label: "Contract Template ID", type: "text", name: "contractTempID" },
				{ label: "Company Logo", type: "text", name: "logo" },
				{ label: "Template Color", type: "text", name: "tempColor" },
			],
		},
		{
			name: "Contact Info",
			fields: [
				{
					label: "Finance Contact Name",
					type: "text",
					name: "financeContactName",
				},
				{
					label: "Finance Contact Email",
					type: "email",
					name: "financeContactEmail",
				},
				{
					label: "Finance Contact Phone",
					type: "tel",
					name: "financeContactPhone",
				},
			],
		},
	];

	const toggleDomain = (domainName) => {
		if (selectedDomain === domainName) {
			setSelectedDomain(null);
		} else {
			setSelectedDomain(domainName);
		}
	};

	const onSubmit = (data) => {
		setIsProcessing(true);

		const domains = Object.keys(data);
		const isEmpty = domains.some((domain) => {
			const fields = data[domain];
			if (
				selectedCountry === "India" &&
				domain === "taxID" &&
				fields.length === 0
			) {
				if (data.taxID.length !== 0) {
					data.taxID = " ";
				}
				return false;
			}
			if (
				selectedCountry !== "India" &&
				(domain === "panNumber" ||
					domain === "cinNumber" ||
					domain === "gstNumber") &&
				fields.length === 0
			) {
				if (data.panNumber.length !== 0) {
					data.panNumber = " ";
				}
				if (data.cinNumber.length !== 0) {
					data.cinNumber = " ";
				}
				if (data.gstNumber.length !== 0) {
					data.gstNumber = " ";
				}
				return false;
			}
			return fields.length === 0;
		});

		if (isEmpty) {
			showErrorToast("Error: Required fields not Filled!");
			setIsProcessing(false);
			return;
		}

		axios
			.put(
				`/api/company/update/${props.companyId}`,
				data,
				{
					headers: {
						Authorization: "Bearer " + sessionStorage.getItem("Token"),
					},
				}
			)
			.then((response) => {
				showSuccessToast(
					response.data && response.data.Message
						? response.data.Message
						: "Success"
				);
				companyData();
				navigate("/companies");
			})
			.catch((err) => {
				console.log(err);
				showErrorToast("Registration Error!");
				setIsProcessing(false);
			})
			.finally(() => {
				setIsProcessing(false);
			});
	};
	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="max-w-3xl mx-auto p-6 bg-gray-100 mt-12 rounded shadow-md"
		>
			{domains.map((domain) => (
				<div
					key={domain.name}
					className={`mb-4 p-6 border rounded transition-colors duration-300 ease-in-out ${
						selectedDomain === domain.name
							? "border-blue-500"
							: "border-gray-300"
					}`}
				>
					<div
						className="flex justify-between items-center cursor-pointer transition-colors duration-300 ease-in-out"
						onClick={() => toggleDomain(domain.name)}
					>
						<h2 className="text-xl font-bold">{domain.name}</h2>
						{selectedDomain === domain.name ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-5 w-5 text-blue-500 transition-transform duration-300 ease-in-out transform rotate-180"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fillRule="evenodd"
									d="M15.293 7.293a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414l-2.5-2.5-2.5 2.5a1 1 0 1 1-1.414-1.414l3-3a1 1 0 0 1 1.414 0zM5 11a1 1 0 0 0-1"
									clipRule="evenodd"
								/>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-5 w-5 text-gray-500 transition-transform duration-300 ease-in-out transform rotate-0"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fillRule="evenodd"
									d="M10 3a1 1 0 0 1 1 1v5h5a1 1 0 0 1 0 2h-5v5a1 1 0 0 1-2 0v-5H4a1 1 0 1 1 0-2h5V4a1 1 0 0 1 1-1z"
									clipRule="evenodd"
								/>
							</svg>
						)}
					</div>
					{selectedDomain === domain.name && (
						<div className="mt-4 transition-all duration-300 ease-in-out">
							{domain.fields.map((field) => (
								<div
									style={{
										display: field.isDisabled ? "none" : "block",
									}}
									className="mb-4"
									key={field.name}
								>
									{field.name === "country" && (
										<div>
											<label
												htmlFor={field.name}
												className="block font-medium mb-2"
											>
												{field.label}:
											</label>
											<select
												className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
												id={field.name}
												{...register(field.name)}
												value={selectedCountry}
												onChange={handleCountryChange}
												disabled={field.Disabled}
												required
											>
												{countries.map((country) => (
													<option key={country.name} value={country.name}>
														{country.name}
													</option>
												))}
											</select>
										</div>
									)}
									{field.name !== "country" &&
										(field.name === "panNumber" ||
											field.name === "cinNumber" ||
											field.name === "gstNumber") && (
											<div>
												<label
													htmlFor={field.name}
													className="block font-medium mb-2"
												>
													{field.label}:
												</label>
												<input
													type={field.type}
													disabled={field.Disabled}
													id={field.name}
													{...register(field.name)}
													className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
													required={selectedCountry === "India"}
												/>
											</div>
										)}
									{field.name !== "country" && field.name === "taxID" && (
										<div>
											<label
												htmlFor={field.name}
												className="block font-medium mb-2"
											>
												{field.label}:
											</label>
											<input
												type={field.type}
												disabled={field.Disabled}
												id={field.name}
												{...register(field.name)}
												className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
												required={selectedCountry !== "India"}
											/>
										</div>
									)}
									{field.name !== "country" &&
										field.name !== "taxID" &&
										field.name !== "panNumber" &&
										field.name !== "cinNumber" &&
										field.name !== "gstNumber" && (
											<div>
												<label
													htmlFor={field.name}
													className="block font-medium mb-2"
												>
													{field.label}:
												</label>
												<input
													type={field.type}
													disabled={field.Disabled}
													id={field.name}
													{...register(field.name)}
													className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
													required
												/>
											</div>
										)}
								</div>
							))}
						</div>
					)}
				</div>
			))}

			<div className="mb-4 p-6 border rounded transition-colors duration-300 ease-in-out border-blue-500">
				<h2 className="text-xl font-bold mb-4">Terms and Conditions</h2>

				<div className="mt-2">
					<textarea
						id="tnc"
						{...register("tnc")}
						className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
						placeholder="Enter T&C"
						value={tnc}
						onChange={(e) => setTnc(e.target.value)}
						rows={tnc ? Math.ceil(tnc.length / 100) : 1}
					></textarea>
				</div>
			</div>

			<button
				type="submit"
				disabled={isProcessing}
				className={`${
					isProcessing
						? "bg-gray-500 hover:bg-gray-700"
						: "bg-blue-500 hover:bg-blue-700"
				} flex mx-auto  text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out`}
			>
				Edit
			</button>
		</form>
	);
};

export default Form;
