import React, { useState, useEffect } from "react";
import axios from "axios";
import { showErrorToast } from "../Utils/toastUtils";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import handleResponse from "../Utils/responseHandler";

const ActivityLogs = () => {
	const { currentColor } = useStateContext();

	const Navigate = useNavigate();

	const [logs, setLogs] = useState([]);
	const [loading, setLoading] = useState(false);
	const fetchData = async () => {
		try {
			setLoading(true);
			const result = await axios.get("/api/logs/dash", {
				headers: {
					Authorization: "Bearer " + sessionStorage.getItem("Token"),
				},
			});
			setLogs(result.data.reverse());
		} catch (error) {
			showErrorToast("Error!");
			handleResponse(error, Navigate);
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const handleLogsClick = (e) => {
		e.preventDefault();
		Navigate("/logs");
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className="step-three xl:w-5/12 h-100 px-4 mt-5 w-25">
			<div className="flex-1 bg-red-50 rounded-lg shadow-xl mt-4 p-8 w-50 animate-slide-in-1">
				<div className="flex flex-row items-center justify-between">
					<h4 className="text-xl text-gray-900 font-bold">Activity log</h4>
					<div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
						<button
							style={{ backgroundColor: currentColor }}
							className="text-white  text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
							type="button"
							onClick={handleLogsClick}
						>
							See all
						</button>
					</div>
				</div>
				<div className="relative px-4">
					{logs ? (
						logs.slice(0, 5).map((item, index) => {
							return (
								<div
									key={index}
									className="flex items-center w-full my-6 -ml-1.5"
								>
									<div className="w-1/12 z-10">
										<div
											style={{ backgroundColor: currentColor }}
											className="w-3.5 h-3.5 rounded-full"
										></div>
									</div>

									<div className="w-11/12">
										<p className="text-sm">{item.message}</p>
										<p className="text-xs text-gray-500">
											{new Date(item.date).toLocaleDateString() +
												" " +
												new Date(item.date).toLocaleTimeString()}
										</p>
									</div>
								</div>
							);
						})
					) : (
						<div className="flex items-center w-full my-6 text-bold -ml-1.5">
							Logs Going To Appear Soon...
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ActivityLogs;
