import React, { useState, useEffect } from "react";
import axios from "axios";
import { showSuccessToast, showErrorToast } from "../Utils/toastUtils";
import Modal from "./modal";
import { useStateContext } from "../contexts/ContextProvider";

const NewPublisherModal = (props) => {
  const { currentColor } = useStateContext();

  const fetchData = props.fetchData;
  const [modalOpen, setModalOpen] = useState(false);
  const [publisherName, setPublisherName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [options, setOptions] = useState([]);
  const [optionId, setoptionID] = useState("");
  const userType = sessionStorage.getItem("role");
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get(
          "/api/company/options",
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("Token"),
            },
          }
        );
        setOptions(response.data);
        setoptionID(response.data[0]._id);
      } catch (error) {
        console.error(error);
      }
    };

    fetchOptions();
  }, []);

  const handleFormData = () => {
    if (publisherName.length === 0) {
      showErrorToast("Input Publisher Name");
      return;
    }
  };

  const handleSubmit = async (event) => {
    setIsProcessing(true);
    event.preventDefault();
    handleFormData();

    try {
      const formData = {
        name: publisherName,
        emailId: emailId,
        companyId: selectedCompany,
      };
      await axios.post(
        "/api/publisher/createbasic",
        formData,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("Token"),
          },
        }
      );

      showSuccessToast(`New publisher "${formData.name}" created successfully`);
      fetchData();
      closeModal();
    } catch (error) {
      console.error(error);
      setIsProcessing(false);
      showErrorToast("Publisher Creation Error!");
    }
    setIsProcessing(false);
    closeModal();
  };

  const openModalHandler = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setPublisherName("");
    setEmailId("");
    setSelectedCompany("");
    setModalOpen(false);
  };

  return (
    <div>
      {userType === "Employee" && (
        <button
          style={{ background: currentColor }}
          className="
    fixed bottom-5 right-24 bg-blue-500 hover:drop-shadow-xl text-white
    font-bold py-2 px-4 rounded transition duration-300 ease-in-out shadow-lg
  "
          onClick={openModalHandler}
        >
          Add Publisher
        </button>
      )}
      <div className="w-full">
        <Modal isOpen={modalOpen} onClose={closeModal}>
          <div className="flex items-center justify-center">
            <div className="bg-white w-full max-w-3xl mx-auto p-12 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-6 text-center rounded-lg">
                Create New Publisher
              </h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="publisherName"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Publisher Name:
                  </label>
                  <input
                    type="text"
                    id="publisherName"
                    name="publisherName"
                    value={publisherName}
                    onChange={(e) => setPublisherName(e.target.value)}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="emailId"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Publisher Mail:
                  </label>
                  <input
                    type="text"
                    id="emailId"
                    name="emailId"
                    value={emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="optionSelect"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Options:
                  </label>
                  <select
                    id="optionSelect"
                    name="optionSelect"
                    className="
                  block appearance-none w-full border py-[0.5rem] px-[1rem]
                  text-gray-700 leading-tight focus:outline-none focus:bg-white
                  focus:border-gray-500 sm:text-sm sm:leading-5 rounded-md
                "
                    value={selectedCompany}
                    onChange={(e) => {
                      setSelectedCompany(e.target.value);
                    }}
                  >
                    <option value="" disabled hidden selected>
                      Select a company
                    </option>

                    {options?.map((role) => {
                      return (
                        <option value={role._id}>{role.companyName}</option>
                      );
                    })}
                  </select>
                </div>

                <div className="text-center mt-4">
                  <button
                    type="submit"
                    style={{ background: currentColor }}
                    disabled={isProcessing}
                    className={`${isProcessing
                        ? "bg-gray-500 hover:bg-gray-700"
                        : "bg-blue-500 hover:bg-blue-700"
                      } flex mx-auto  text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out`}
                  >
                    Create New Publisher
                  </button>
                </div>
              </form>
              <button
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                onClick={closeModal}
              >
                <svg
                  className="w-6 h-6 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M13.41 12l4.29-4.29a1 1 0 1 0-1.41-1.41L12 10.59 7.71 6.29a1 1 0 0 0-1.41 1.41L10.59 12l-4.3 4.29a1 1 0 0 0 1.42 1.42L12 13.41l4.29 4.3a1 1 0 0 0 1.42-1.42L13.41 12z" />
                </svg>
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default NewPublisherModal;
